.create-user {
  .tool-comp {
    p {
      margin-bottom: 0px;
    }
  }
  .tool {
    cursor: pointer;
    position: relative;
    display: inline-block;
  }
  .tooltiptext {
    visibility: hidden;
    width: 100px;
    height: 70px;
    background-color: #050505;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: -100%;
    left: 162%;

    opacity: 0;
    transition: opacity 0.3s;
    font-size: 13px;
  }

  .tool .tooltiptext::after {
    content: '';
    position: absolute;
    top: 35%;
    left: -5%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
    transform: rotate(90deg);
  }

  .tool:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
  .card-wrapper {
    // display: flex;
    justify-content: center;
    .form-wrapper {
      width: 50%;
      margin: auto;
      padding: 1rem 0rem;
      input[type='file'] {
        display: none;
      }
    }

    .inputs {
      width: 40%;
    }
    .inputs1 {
      width: 40%;
    }
    .inputs2 {
      width: 55%;
    }

    .prop-label {
      text-align: left;
      font-size: 16px;

      color: var(--clr-text-100);
      margin-bottom: 0.5rem !important;
    }
    .actions {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
@media (max-width: 796px) {
  .form-wrapper {
    width: 100% !important;
  }
}

.user-details {
  .values {
    font-size: 14px;
    color: #acacac;
    margin-left: 3px;
    overflow-wrap: break-word;
  }

  .border-right {
    border-right: 1px solid white;
  }

  .list {
    margin-bottom: 0.8rem;
    padding-top: 0.6rem;
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid var(--clr-border-200);

    input {
      margin-right: 0.6rem;
    }

    p {
      font-weight: 600;
    }

    .actions:hover {
      color: var(--clr-primary-500) !important;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .tool {
    cursor: pointer;
    position: relative;
    display: inline-block;
  }
  .tooltiptext {
    visibility: hidden;
    width: 100px;
    height: 70px;
    background-color: #050505;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: -100%;
    left: 162%;

    opacity: 0;
    transition: opacity 0.3s;
    font-size: 13px;
  }

  .tool .tooltiptext::after {
    content: '';
    position: absolute;
    top: 35%;
    left: -5%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
    transform: rotate(90deg);
  }

  .tool:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}

.assign-role-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #303030;
  margin: 34px 0px 0px 0px;
  border-radius: 6px;
  padding: 20px 12px;
}

.activated {
  color: #0cc406 !important;
}

.deactivated {
  color: hsl(0, 87%, 58%) !important;
}
