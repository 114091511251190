.Crumbs {
  p {
    margin-bottom: 0px !important;
  }

  .inActive {
    color: var(--clr-text-300);
    cursor: pointer;
    font-size: 1rem;
  }

  .active {
    color: var(--clr-text-100);
    font-size: 1rem;
    font-weight: 600;
  }
}
