.mw-170 {
  min-width: 170px;
}
.data-source-add {
  .card-wrapper {
    justify-content: center;
    .form-wrapper {
      width: 50%;
      padding: 1rem 0rem;
      margin: auto;
      input[type='file'] {
        display: none;
      }
    }

    .inputs {
      width: 40%;
    }

    .prop-label {
      text-align: left;
      font-size: 16px;

      color: var(--clr-text-100);
      margin-bottom: 0.5rem !important;
    }
    .actions {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  @media (max-width: 796px) {
    .form-wrapper {
      width: 100% !important;
    }
  }
}
