.cluster-details {
  .values {
    font-size: 14px;
    color: #acacac;
    margin-left: 3px;
    overflow-wrap: break-word;
  }

  .border-right {
    border-right: 1px solid white;
  }

  .stop-btn {
    border-radius: 0.25rem;
    padding: 5px 10px;
  }

  .cluster-tabs {
    .tab {
      width: 25%;
    }

    .in-active {
      color: #7e7e7e;
      cursor: pointer;
    }

    .active {
      color: #2a7ffe;
      padding-bottom: 5px;
      border-bottom: 2px solid #2a7ffe;
      cursor: pointer;
    }
  }
}

@media (max-width: 768px) {
  .form-div {
    width: 100% !important;
  }
  .wrapper {
    padding: 1rem 5rem !important;
  }
}

.cluster-add {
  .card-wrapper {
    display: flex;
    justify-content: center;
    .form-wrapper {
      width: 55%;
      padding: 1rem 0rem;
      input[type='file'] {
        display: none;
      }
    }
  }

  @media (max-width: 796px) {
    .form-wrapper {
      width: 100% !important;
    }
  }

  .prop-label {
    text-align: left;
    font-size: 16px;

    color: var(--clr-text-100);
    margin-bottom: 0.5rem !important;
  }

  .text {
    border: 1px solid white;
    margin-bottom: 1rem;
  }
  .inputs {
    width: 40%;
    display: flex;
    gap: 1em;
  }
}

@media screen and (max-width: 950px) {
  .data-btns {
    padding-right: 3px !important;
  }
}
