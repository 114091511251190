.table > :not(caption) > * > * {
  padding: 0 !important;
  background-color: transparent;
  border-bottom-width: 0 !important;
  box-shadow: none !important;
}

.tableX th,
.tableX td {
  padding: 1rem !important;
  color: var(--clr-table-text) !important;
  font-size: 15px;
  border-bottom: 2px solid var(--clr-table-border) !important;
}

.tableX th > span,
.tableX td > span {
  padding: 0;
}

.tableX th,
.tableX th span {
  color: var(--clr-table-text-head) !important;
  font-weight: bold;
}

.tableX th svg,
.tableX td > span,
.tableX td > i {
  color: var(--clr-table-text) !important;
}

.tableX tr:hover td > span,
.tableX tr:hover td > i {
  background-color: transparent !important;
}

.tableX td span.id {
  color: var(--clr-table-text-id) !important;
  cursor: pointer;
}

.tableX td span.id:hover {
  text-decoration: underline;
}

.tableX td span.pending {
  color: var(--clr-warning) !important;
}

.tableX td span.available {
  color: var(--clr-success) !important;
}

.tableX td span.disabled {
  color: var(--clr-disable) !important;
}

.tableX td span.deleted {
  color: var(--clr-danger) !important;
}

.tableX tr.tr-td-border-transparent td {
  border-color: transparent !important;
}

.tableX.new {
  th {
    background-color: var(--clr-table-background) !important;

    @media screen and (max-width: 768px) {
      display: none !important;
    }
  }

  tr {
    background-color: var(--clr-table-background) !important;

    @media screen and (max-width: 768px) {
      &:nth-of-type(2n) {
        background-color: var(--clr-table-background-even) !important;
      }
    }

    &:hover {
      background-color: var(--clr-table-background-even) !important;
    }

    .MuiPaper-root {
      box-shadow: none;
      background-color: var(--clr-table-background-even);
    }
  }

  td {
    @media screen and (max-width: 768px) {
      display: grid !important;
      grid-template-columns: 20ch auto;
      padding: 0.5rem 1rem !important;

      &:first-child {
        padding-top: 2rem !important;
      }

      &:last-child {
        padding-bottom: 2rem !important;
      }

      &:after {
        justify-self: end;
      }

      &:before {
        content: attr(data-cell) ': ' !important;
        font-weight: 700;
        text-transform: capitalize;
        color: var(--clr-text-100);
      }
    }

    .tr-special-row {
      width: 100%;
      margin: 0;
      padding: 0;
      padding-left: 2rem;
      padding-right: 2rem;
      background-color: var(--clr-card-200);
      z-index: 1;
    }

    &.tr-td-special-row {
      padding: 0 !important;
      display: table-cell !important;
      background-color: var(--clr-table-background-even);

      @media screen and (max-width: 768px) {
        &:before {
          content: '' !important;
        }
      }
    }
  }
}

.mobile-table .mob-main {
  background-color: var(--clr-table-background);
  margin-bottom: 5px;
}

.mobile-table .mob-main:nth-child(even) {
  background-color: var(--clr-table-background-even);
}

.mobile-table .mob-main .mob-rows {
  display: flex;
  justify-content: space-between;
  padding: 0.8rem;
  border-bottom: 1px solid var(--clr-table-border);
  font-size: 0.8em;
}

.mobile-table .mob-main .mob-rows:last-child {
  border-bottom: 0;
}

.mobile-table .mob-main .mob-rows .id {
  color: var(--clr-table-text-id) !important;
  cursor: pointer;
}

.mobile-table .mob-main .mob-rows .id:hover {
  text-decoration: underline;
}

.mobile-table .mob-main .mob-rows .keys {
  font-weight: bold;
  color: var(--clr-table-text);
}

.mobile-table .mob-main .mob-rows .values {
  color: var(--clr-table-text);
}

@media screen and (max-width: 991px) {
  .desktop-table {
    display: none !important;
  }
}

@media screen and (min-width: 992px) {
  .mobile-table {
    display: none;
  }
}

.table-pagination p,
.table-pagination svg,
.table-pagination [role='combobox'] {
  color: var(--clr-table-text) !important;
  margin-bottom: 0;
}

.table-pagination button:disabled {
  opacity: 0.5;
}
