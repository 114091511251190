.common-card {
  min-height: 3rem;
  padding: 1rem;
  background-color: var(--clr-card-100);
  border: 1px solid var(--clr-card-200);
  border-radius: 11px;
}

.form-card {
  min-height: 3rem;
  padding: 1rem;
  background-color: var(--clr-card-100);
  border-radius: 11px;
}
