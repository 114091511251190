$white: #ffffff;
$black: #000000;
$lightGray: #e6e6e6;
$grayShade1: #f9fafa;
$pattensBlue: #dddfe1;
$mischka: #abafb3;
$slategray: #78828c;
$resolutionBlue: #093f7c;
$whisper: #eeeeee;
$neonBlue: #623ff1;
$hanPurple: #5833f1;
$mediumSlateBlue: #7f63f4;
$darkGray: #aaaaaa;
$tuna: #464a53;
$HanPurple: #441cef;
$greenYellow: #adff2f;
