.dp-wrapper {
  display: block;
  clear: both;
  float: none;
  margin: 0px auto;
  padding: 0px;
  width: 100%;
}

.dp-section {
  display: flex;
  padding: 0px;
  width: 100%;
  justify-content: space-between;
  position: fixed;
  z-index: 100 !important;
}

.dp-section-center {
  display: flex;
  width: 100%;
}

.dp-section-data {
  display: block;
  clear: both;
  float: left;
  margin: 0px 0px 0px 0px;
  padding: 0px;
  width: 100%;
}

.dp-sction-data-1 {
  display: flex;
}

.dp-sction-data-2 {
  display: flex;
  justify-content: flex-end;
}

#dp-section_content {
  background-color: var(--clr-header-background);
}

.dp-header {
  display: flex;
  height: 54px;
  width: 140px;
}

.dp-section-data-group-1 {
  display: flex;
  padding: 0px 0px 0px 0px;
}

.dp-section-data-group-2 {
  display: flex;
  padding: 0px 15px 0px 0px;
}

.noti-wrapper {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #3b3b3b;
  /* justify-content: space-between; */
}

.ack-btn {
  font-size: 2rem;
  color: #787878;
  cursor: pointer;
}

.ack-btn:hover {
  color: white;
}

.dp-breadcrumb {
  display: flex;
  color: #fff;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 0px 24px;
}

.dp-user-name {
  display: flex;
  color: var(--clr-header-text);
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 0px 8px;
}

.dp-right-icon-group {
  display: flex;
}

.dp-date-time-marker {
  display: flex;
  color: var(--clr-header-text);
  font-size: 1rem;
  justify-content: center;
  align-items: center;
}

/*----------DASHBOARD CCS STARTS----------------------*/
.dp-section-dash-container {
  display: flex;
  width: 100%;
  /*height: 100vh;*/
  /*background-color: aqua;*/
}

.dp-section-left-nav {
  display: flex;
  width: 226px;
  height: 100vh;
  background-color: #3b3b3b;
}

.card {
  display: flex;
  padding: 5px 5px 5px 5px;
  margin: 5px 5px 5px 5px;
  border: 1px solid #212121 !important;
  border-radius: 6px;
  background-color: #3b3b3b !important;
  flex-direction: column;
}

.noti-wrap {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.noti-card-heading {
  display: flex;
  font-size: 20px;
  font-weight: 700;
  padding: 6px 5px 6px 10px;
  color: var(--clr-text-300);
  justify-content: space-between;
  margin: 0px !important;
  margin-bottom: 0px !important;
  padding-right: 5px;
}

.dp-ref-image {
  display: flex;
  justify-content: center;
  padding: 10px 10px 10px 10px;
}

.dp-ref-image img {
  width: 100%;
}

.dp-cluster-card {
  display: flex;
  width: 43%;
  flex-direction: column;
  overflow: hidden;
  margin: 5px;
}

.dp-jobs-card {
  display: flex;
  width: 60%;
  flex-direction: column;
  overflow: hidden;
  margin: 5px;
}

.dp-admin-prop-cont {
  display: flex;
  padding: 0px 0px 5px 0px;
  justify-content: space-around;
}

.dp-table-cont {
  width: 15%;
  margin: 0px 5px 0px 5px;
  color: var(--clr-text-300);
}

.dp-prop-table table {
  border-collapse: collapse;
  width: 100%;
}

.dp-prop-table table th {
  font-size: 1.2rem;
}

.dp-prop-table table tbody tr {
  border-bottom: 1px solid #5a5a5a;
}

.dp-prop-table table tbody tr:nth-child(1) {
  border: #3b3b3b;
}

.dp-prop-table table tbody tr:last-child {
  border: #3b3b3b;
}

.text-right {
  text-align: right;
}

.dp-notification-card-cont {
  display: flex;
  width: 100%;
  height: calc(50% - 30px);
}

.dp-noti-counts {
  background-color: #81bbff;
  border-radius: 50%;

  width: 24px;
  height: 24px;
  font-size: 16px;
  color: #000000;
}

.dp-notification-card {
  width: 100%;
  margin: 5px;
  margin-bottom: 15px;
  height: 300px;
}

.h-400 {
  height: 400px;
}

.h-90 {
  height: 90% !important;
}

.dp-notification-list-wrapper {
  display: flex;
  width: 100%;
  position: relative;
  height: 85%;
  padding: 0% 0% 0% 0%;
}

.dp-notification-list-cont {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(85% - 35px);
}

.dp-notificaton-left-side {
  height: 90%;

  background-color: #292929;
  width: 45%;
}

.input-search {
  background-color: #3b3b3b;
  border: 1px solid #8d8d8d;
  border-radius: 4px;
  padding: 0.25rem 1rem;
  margin-bottom: 2rem;
}

.dp-notification {
  display: flex;
  width: 94%;
  flex-direction: column;
  flex-wrap: wrap;
  color: var(--clr-text-300);
  margin: 10px 0px 0px 0px;
  /* border-bottom: 1px solid #666; */
  padding: 0px 0px 6px 20px;
}

.dp-notification-list {
  width: 40%;
  margin-right: 0px !important;
}

.dp-notification-full-details {
  width: 60%;
  margin-left: 0px !important;
  background-color: white;
  height: calc(100% - 30px);
  overflow: auto;
  background-color: #3b3b3b;
  border-radius: 0px;
  padding: 10px;
  border-left: none;
  border-bottom: none;
  border-right: none;
  border-top: 1px solid #292929;
}

.dp-noti-msg-wrapper {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #656565;
}

.dp-noti-msg {
  color: var(--clr-text-300) !important;

  padding-bottom: 0.5rem;
}

.dp-notifiction-prop {
  color: #acacac;
  font-size: 20px;
  margin-top: 1rem;
  margin-bottom: 0px;
}

.noti-text {
  color: #edeaea !important;
}

.selected-noti {
  background-color: #3b3b3b;
  /* border: 1px solid #212121; */
  color: #ffffff;
  border-right: none;
  border: none;
}

.dp-notification p {
  text-align: left;
  margin-bottom: 1px;
}

.noti-not-checked {
  background-color: transparent !important;
  border: 1px solid #acacac !important;
}

.dp-notification-title {
  font-size: 15px;
  font-weight: 700;
  color: #a7a7a7;
}

.dp-notification-time,
.dp-notification-details {
  font-size: 0.8rem;
  line-height: 19px;
}

.dp-bookmarks-cont {
  padding-bottom: 25px;
}

.dp-bookmark {
  height: 50%;

  margin: 5px;

  /* width: 100%; */
}

.dp-new-notifications {
  height: 6px;
  width: 6px;
  background-color: #81bbff;
}

.dp-noti-props {
  text-align: left;
  font-size: 15px;
  color: #acacac !important;
  padding: 0.5rem;
}

.admin-threads {
  color: var(--clr-text-300) !important;
  font-size: 14px;
  text-align: center !important;
  border: none !important;
}

.admin-td {
  color: var(--clr-text-300) !important;
  font-size: 16px;
}

@media screen and (max-width: 950px) {
  .dp-notification-list-wrapper {
    flex-direction: column;
  }

  .dp-notificaton-left-side {
    width: 100%;
    height: 45%;
  }

  .dp-notification-full-details {
    width: 100%;
    height: 45%;
  }
}

@media (max-width: 786px) {
  .noti-search {
    width: 94% !important;
  }
}

/*----------DASHBOARD CCS ENDS----------------------*/
@media only screen and (max-width: 900px) {
  .dp-right-icon-group {
    display: none;
  }

  .dp-right-menu-icon {
    display: flex;
  }
}

@media only screen and (max-width: 800px) {
  .dp-notification-card-cont {
    width: 100%;
    max-height: 400px;
  }

  .dp-notification-list-cont {
    position: inherit;
  }
}

@media only screen and (max-width: 800px) {
  .dp-jobs-card {
    width: auto;
  }

  .dp-cluster-card {
    width: auto;
  }

  .dp-admin-prop-cont {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .dp-table-cont {
    width: 80%;
  }
}

@media only screen and (max-width: 390px) {
  .dp-section-data-group-1 {
    padding: 0px 0px 0px 8px;
  }

  .dp-section-data-group-2 {
    padding: 0px 8px 0px 0px;
  }

  .dp-breadcrumb {
    margin: 0px 0px 0px 10px;
  }

  .dp-menu-icon {
    margin: 0px 4px 0px 0px;
  }
}

@media only screen and (max-width: 375px) {
  .dp-section-data-group-1 {
    padding: 0px 0px 0px 0px;
  }

  .dp-section-data-group-2 {
    padding: 0px 0px 0px 0px;
  }
}

.dashboard-layout {
  width: 100%;
  display: grid;

  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;
}

.dashboard-layout :nth-child(1) {
  grid-column: 1/3;
}

.dashboard-layout :nth-child(2) {
  grid-column: 3/6;
}

.dashboard-layout :nth-child(3) {
  grid-column: 1/6;
}

@media only screen and (max-width: 768px) {
  .dashboard-layout :nth-child(1) {
    grid-column: 1/6;
  }

  .dashboard-layout :nth-child(2) {
    grid-column: 1/6;
  }

  .dashboard-layout :nth-child(3) {
    grid-column: 1/6;
  }
}

.card-heading {
  color: var(--clr-text-100);
}
