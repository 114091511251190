.details-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.4rem;

  .details-section-1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.4rem;

    > * {
      flex: 1;
    }

    @media (min-width: 768px) {
      flex-direction: row;
    }

    @media (min-width: 1200px) {
      width: 80%;
    }

    @media (min-width: 1400px) {
      width: 90%;
    }
  }

  .details-section-2 {
    width: 100%;
    display: flex;
    gap: 1.4rem;

    > * {
      flex: 1;
    }

    @media (min-width: 768px) {
      width: 50%;
    }

    @media (min-width: 1200px) {
      width: 20%;
      justify-content: end;
    }

    @media (min-width: 1400px) {
      width: 10%;
      justify-content: end;
    }
  }

  @media (min-width: 1200px) {
    flex-direction: row;
  }

  .details-button-group {
    max-width: min-content;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
