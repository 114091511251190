.footer-main {
  height: 50px;
  width: 100%;
  padding: 1rem;
  background-color: var(--clr-footer-background);
  color: var(--clr-footer-text) !important;
  text-align: left;
  position: fixed;
  bottom: 0px;
  border: 1px solid var(--clr-footer-border);
  z-index: 10;
}

.link-a {
  color: var(--clr-footer-text-active) !important;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
