.not-found {
  height: 100vh;
  background-color: var(--clr-background-50);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  h1 {
    font-size: 4em !important;
    color: var(--clr-text-100) !important;
  }

  p {
    font-size: 1.5rem !important;
    color: var(--clr-text-500) !important;
    margin-bottom: 1.2rem;
  }
}
