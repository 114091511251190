.auth-page {
  height: 100vh;
  background-color: var(--clr-background-100);
  display: flex;
  justify-content: center;
  align-items: center;

  .auth-card {
    width: 25rem;
    padding: 1rem;
    background-color: var(--clr-background-100);
    border-radius: 0.5rem;

    .auth-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;

      .auth-logo {
        height: 3rem;
        width: 3rem;
        background: var(--data-logo) no-repeat;
        background-size: contain;
      }

      .auth-subtitle {
        color: var(--clr-text-300);
      }
    }

    .auth-body {
      margin-top: 1rem;
      margin-bottom: 1rem;

      .auth-input {
        max-width: 45rem;
      }

      .auth-buttons {
        display: flex;
        gap: 1rem;

        button {
          flex: 1;
        }
      }
    }

    .auth-footer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
