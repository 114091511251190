.create-role {
  .card-wrapper {
    justify-content: center;

    .form-wrapper {
      width: 50%;
      padding: 1rem 0rem;
      margin: auto;

      input[type='file'] {
        display: none;
      }
    }
  }

  @media (max-width: 796px) {
    .form-wrapper {
      width: 100% !important;
    }
  }
}

.role-details {
  .values {
    font-size: 14px;
    color: #acacac;
    margin-left: 3px;
    overflow-wrap: break-word;
  }

  .border-right {
    border-right: 1px solid white;
  }

  .actions:hover {
    cursor: pointer;
    // text-decoration: underline;
    color: #51a2ff !important;
  }

  .permission-wrapper {
    display: flex !important;
    align-items: center;
    margin: 0.5rem;

    .sec-1 {
      width: 80%;
    }

    .sec-2 {
      display: flex;
      width: 20%;
      justify-content: space-evenly;
    }
  }

  .perm-sep {
    display: flex;
    width: 100%;
    height: 1px;
    background-color: #4e4e4e;
  }
}

.assign-perm-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

@media (max-width: 900px) {
  .sec-1 {
    width: 60% !important;
  }

  .sec-2 {
    width: 38% !important;
  }
}

@media (max-width: 500px) {
  .sec-1 {
    width: 60% !important;
  }

  .sec-2 {
    flex-direction: column !important;
  }
}
