h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
th,
td,
li,
a {
  font-family: 'Lato', sans-serif !important;
  color: var(--clr-text-300);
}

.pending {
  color: var(--clr-warning) !important;
}

.available {
  color: var(--clr-success) !important;
}

.disabled {
  color: var(--clr-disable) !important;
}

.no-data-msg {
  margin: 1rem;
  padding: 1rem;
  color: var(--clr-text-300) !important;
  font-weight: bold;
  font-style: italic;
  border-bottom: 1px solid var(--clr-border-200);
}

/* Details Page Info */

.details-data-row {
  width: 100%;
  margin-bottom: 1.4rem;
  display: flex;
  gap: 0.5rem;
}

.details-data-label {
  margin-bottom: 0;
  color: var(--clr-text-100);
  font-weight: 600;
}

.details-data-value {
  margin-bottom: 0;
  max-width: 75%;
  color: var(--clr-text-100);
  font-weight: 400;
}

.copy-id {
  color: var(--clr-text-100);
  cursor: pointer;
}

.copy-id:hover .copy-icon {
  color: var(--clr-primary-500);
  display: inline-block;
}

.copy-icon {
  margin-left: 10px;
  color: var(--clr-text-500);
  cursor: pointer;
  display: inline-block;
}

.view-more {
  color: var(--clr-primary-500) !important;
  cursor: pointer;
}

@media (max-width: 786px) {
  .details-data-value {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 1200px) {
  .details-data-row {
    flex-direction: column;
  }
}

.dp-sep {
  height: 100%;
  width: 1px;
  background-color: var(--clr-border-200);
}

/* @media screen and (max-width: 992px) {
    .dp-sep {
        height: 1px;
        width: 100%;
    }
} */
