.query_profile {
  // height: 100vh;
  // padding: 4rem;
  // padding-left: 8rem;
  // padding-right: 8rem;
  // background-color: var(--clr-background-100);
  // overflow-y: scroll;

  .query-card-section {
    border: 1px solid var(--clr-card-200);
    border-radius: 0.5rem;

    .section-header {
      padding: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--clr-primary-500);
      color: #ffddaa;
      border-radius: 0.5rem;

      h5 {
        color: var(--clr-primary-btn-text);
        margin-bottom: 0;
        font-weight: bold;
      }

      .icons-section {
        display: flex;
        justify-content: left;
        align-items: center;
        gap: 0.5rem;
      }

      .query-icon {
        padding: 0.5rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        background-color: var(--clr-primary-btn-text);
        color: var(--clr-primary-500);
        border-radius: 0.3rem;

        i.rotate-0 {
          rotate: 0deg !important;
        }

        i.rotate-180 {
          rotate: 180deg !important;
        }
      }
    }

    .section-body {
      border-radius: 0.5rem;
      background-color: var(--clr-code-background) !important;

      > pre {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        background-color: var(--clr-code-background) !important;
      }

      &.statement {
        font: 1rem monospace;
        line-height: 1.5;
        color: var(--clr-text-300);
      }

      &.graph-section {
        height: 45rem;
        border-radius: 0.3rem;

        .graph {
          background-color: var(--clr-graph-background) !important;
        }

        .custom-node {
          border-radius: 5px;
          display: flex;
          flex-direction: column;
        }

        .node-content {
          display: flex;
          flex-direction: column;
        }

        .node-head {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 1rem;
          background-color: var(--clr-graph-node-head-background);
          color: var(--clr-graph-node-head-text);
          border-bottom: 1px solid #9f9f9f;
          padding: 0.8rem;

          .node-head-expand {
            padding: 0.2rem;
            padding-left: 0.4rem;
            padding-right: 0.4rem;
            background-color: var(--clr-graph-node-body-background);
            color: var(--clr-graph-node-body-text);
            border-radius: 0.2rem;
          }
        }

        .node-head > h5 {
          margin: 0;
        }

        .node-head button {
          height: 1rem;
          width: 1rem;
        }

        .node-body {
          padding: 0.8rem;
          background-color: var(--clr-graph-node-body-background);
          color: var(--clr-graph-node-body-text);
        }

        .node-details {
          width: 25rem;
          position: absolute;
          top: 80px;
          right: 0;
          background-color: var(--clr-graph-node-body-background);
          color: var(--clr-graph-node-body-text);
          border: 1px solid #c1c1c1;
          border-radius: 5px;
          z-index: 10;
        }

        .node-details-head {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 2rem;
          background-color: var(--clr-graph-node-head-background);
          color: var(--clr-graph-node-head-text);
          border-bottom: 1px solid #9f9f9f;
          padding: 0.8rem;
        }

        .node-details-head > h4 {
          margin: 0;
        }

        .node-details-head > h5 {
          margin: 0;
          padding: 0;
        }

        .node-details-body {
          padding: 0.8rem;
        }

        .svg-container {
          width: 100% !important;
        }

        .js-plotly-plot {
          width: 100%;
          height: 700px;
          overflow-x: hidden;
          overflow-y: auto;
        }

        pre {
          background-color: #eeffee !important;
        }

        .download-image.react-flow {
          background: #1a365d;
        }

        .download-image .react-flow__node {
          width: 50px;
          height: 50px;
          color: white;
          font-weight: 700;
          display: flex;
          justify-content: center;
          align-items: center;
          border-color: white;
        }

        .download-image .react-flow__node-custom {
          font-size: 12px;
          background: #eee;
          border: 1px solid #555;
          border-radius: 5px;
          text-align: center;
          padding: 10px;
        }

        .download-image .react-flow__node-custom .react-flow__handle-right {
          transform: none;
        }

        .download-image .download-btn {
          border: 1px solid #eee;
          background: #ebf8ff;
          padding: 10px 20px;
          border-radius: 5px;
          font-weight: 700;
          cursor: pointer;
        }

        .download-image .download-btn:hover {
          opacity: 0.9;
        }
      }
    }
  }
}
