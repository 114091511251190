.select-resource-id {
  height: 200px;
  overflow-y: auto;

  .resource-div {
    margin-left: 10px;
    display: inline;
  }
}

.assign-permission {
  height: 200px;
  overflow-y: auto;

  .permission-div {
    margin-left: 10px;
    display: inline;
  }
}

@media (min-width: 786px) {
  .resourceId-modal {
    max-width: 60vw !important;
  }
}

.add-permission-section {
  height: 200px;
  background-color: var(--clr-background-200);
}

.add-permission-tile {
  padding: 0.8em;
  background-color: var(--clr-background-200);

  &.active {
    background-color: var(--clr-background-300);
  }
}

.add-permission-search {
  padding: 0.8em !important;
  width: 100% !important;
  background-color: var(--clr-background-200) !important;
  border: 0 !important;

  &:focus {
    outline: 0 !important;
  }
}

.add-permission-radio-group {
  padding: 0.5em;
  background-color: var(--clr-background-200);
  display: flex;
  flex-wrap: wrap;

  .form-check {
    min-width: 8em;
    flex: 1;
  }
}
