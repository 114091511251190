.add-edit-page.card-wrapper .form-wrapper {
  width: 50%;
  padding: 1rem 0rem;
  margin: auto;

  @media (max-width: 796px) {
    width: 100% !important;
  }
}

.edit-sensitive-border {
  padding: 0.5em;
  border: 1px solid var(--clr-border-800);
  border-radius: 0.5em;
}

.edit-sensitive-border.edit-sensitive-disable {
  border-color: var(--clr-border-500);
}

.edit-sensitive-field {
  width: max-content;
  background-color: var(--clr-card-100);
  color: var(--clr-text-100);
  position: absolute;
  top: -20px;
  right: 20px;
}

.MuiFormControl-root {
  background-color: var(--clr-input-background);

  & .MuiInputLabel-root {
    margin-top: -0.4rem;
    color: var(--clr-input-text);

    &.MuiInputLabel-shrink {
      margin-top: 0;
      padding: 0 0.3rem 0 0.3rem;
    }

    &.Mui-focused {
      color: var(--clr-input-text);
    }

    &.Mui-disabled {
      color: var(--clr-input-text);
    }
  }
  & .MuiInputBase-root {
    color: var(--clr-input-text);

    &.Mui-disabled {
      background-color: var(--clr-input-background-disable);
    }
  }
  & .MuiInputBase-input {
    padding: 10px 14px;
  }
  & .MuiInputBase-input.Mui-disabled {
    color: var(--clr-input-text);
    -webkit-text-fill-color: var(--clr-input-text);
    cursor: not-allowed;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border: 1px solid var(--clr-input-border);
      border-radius: 4px;
    }
    &.Mui-disabled fieldset {
      border: 1px solid var(--clr-input-border);
    }
    &:hover fieldset {
      border: 1px solid var(--clr-input-border);
    }
    &.Mui-focused fieldset {
      border: 1px solid var(--clr-input-border-focus);
    }
  }
  & .MuiSvgIcon-root {
    color: var(--clr-input-text);

    &.Mui-disabled {
      color: var(--clr-input-text);
    }
  }
  & .MuiIconButton-root {
    color: var(--clr-input-text);

    &.Mui-disabled {
      color: var(--clr-input-text);
    }
  }
}

.filter-section {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto;
  grid-gap: 1rem;

  &.query-profile {
    grid-template-columns: repeat(6, 1fr);
  }

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 576px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.MuiChip-root {
  background-color: var(--clr-background-300) !important;
  color: var(--clr-input-text) !important;
}
