.pieContainer {
  height: 40%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.relative {
  position: relative;
}

.total {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}
.graph-wrapper {
  width: 60%;
  align-self: center;
}

.graph-wrapper-height {
  height: 250px;
}

@media (min-width: 1600px) {
  .pieContainer {
    top: 53%;
  }

  .total {
    font-size: 1rem;
    margin-bottom: 0.2rem;
  }

  .tvalue {
    font-size: 1rem;
  }
}

@media (min-width: 1200px) and (max-width: 1600px) {
  .pieContainer {
    top: 50%;
  }

  .graph-wrapper {
    width: 80%;
  }
  .total {
    font-size: 1rem;
    margin-bottom: 0.2rem;
  }

  .tvalue {
    font-size: 1rem;
  }
}

@media (min-width: 990px) and (max-width: 1199px) {
  .pieContainer {
    top: 55%;
  }
  .graph-wrapper {
    width: 80%;
  }
  .total {
    font-size: 1rem;
    margin-bottom: 0.2rem;
  }

  .tvalue {
    font-size: 1rem;
  }
}

@media (min-width: 920px) and (max-width: 989px) {
  .pieContainer {
    top: 50%;
  }
  .graph-wrapper {
    width: 70%;
  }
  .total {
    font-size: 1rem;
    margin-bottom: -0.1rem;
  }
}

@media (min-width: 800px) and (max-width: 920px) {
  .pieContainer {
    top: 55%;
  }

  .total {
    font-size: 1rem;
    margin-bottom: -0.1rem;
  }
  .tvalue {
    font-size: 1rem;
  }
}

// @media (min-width:800px) and (max-width:910px) {
//   .pieContainer {

//     top: 40%;

//   }

//   .total {
//     font-Size: .6rem;
//     margin-Bottom: -.1rem,
//   }
// }

@media (min-width: 641px) and (max-width: 800px) {
  .pieContainer {
    top: 55%;
  }

  .total {
    font-size: 1rem;
    margin-bottom: -0.1rem;
  }
  .tvalue {
    font-size: 1rem;
  }
}

@media (min-width: 600px) and (max-width: 640px) {
  .pieContainer {
    top: 55%;
  }

  .total {
    font-size: 1rem;
    margin-bottom: -0.1rem;
  }
  .tvalue {
    font-size: 1rem;
  }
}

@media (min-width: 800px) and (max-width: 919px) {
  .graph-wrapper {
    width: 100%;
  }
}
@media (min-width: 371px) and (max-width: 500px) {
  .pieContainer {
    top: 50%;
  }
  .graph-wrapper {
    width: 100%;
  }

  .total {
    font-size: 1rem;
    margin-bottom: 0.2rem;
  }

  .tvalue {
    font-size: 1rem;
  }
}

@media (min-width: 320px) and (max-width: 370px) {
  .pieContainer {
    top: 50%;
  }
  .graph-wrapper {
    width: 100%;
  }

  .total {
    font-size: 1rem;
    margin-bottom: 0.2rem;
  }
}

@media screen and (max-width: 320px) {
  .pieContainer {
    top: 44%;
  }
  .graph-wrapper {
    width: 100%;
  }

  .total {
    font-size: 1rem;
    margin-bottom: -0.1rem;
  }
}
