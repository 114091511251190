.logoHidden {
  display: none;
}
.hamburger {
  font-size: 30px;
  cursor: pointer;
}

@media only screen and (max-width: 1140px) {
  .desktop {
    display: none;
  }
}

@media screen and (min-width: 1140px) {
  .mobile-sidebar {
    display: none;
  }
}
